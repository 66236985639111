import styled from "styled-components";

const StyledProdukHukum = styled.div`
  ul {
    flex-wrap: wrap;
    padding-top: 2rem;
  }
`;

export default StyledProdukHukum;
