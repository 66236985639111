import styled from "styled-components";

const CardHeader = styled.div`
  img {
    width: 100%;
    max-height: 100%;
  }
`;

export default CardHeader;
