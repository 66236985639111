import dpm1 from "../assets/image/dpm-1.jpg";
import dpm2 from "../assets/image/dpm-2.jpg";
import dpm3 from "../assets/image/dpm-3.jpeg";
import dpm4 from "../assets/image/dpm-4.jpg";

export const DpmPhoto = [
  { url: dpm1 },
  { url: dpm2 },
  { url: dpm3 },
  { url: dpm4 },
];
